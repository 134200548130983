import React from 'react';

import Cover from '../components/cover.component';

import { ReactTinyLink } from 'react-tiny-link';

import { Section, SectionGray } from '../static/styles/style';

import { useMediaQuery } from '../helpers/hook';

import {
  Database,
  Security,
  Application,
  Framework,
  Market,
  Integration,
  BuiltIn,
  Opportunity
} from '../static/index';

const Main = () => {
  const isHandHeld = useMediaQuery('(max-width: 650px)');

  return (
    <React.Fragment>
      <Section>
        <br />
        <Cover />
        <br />
        <div
          id='intro'
          style={{ margin: '2vw', marginLeft: '10vw', marginRight: '10vw' }}
        >
          <h1
            className='display-4 text-center fw-bold'
            style={{ marginTop: '5vw', marginBottom: '5vw' }}
          >
            Empowering MLSs To Fully Control Their Backend Ecosystem
          </h1>
          <p className='fs-5'>
            Amplify combined with the most comprehensive listing maintenance 
            module, gives Multiple Listing Services the ability to fully control and 
            manage their most valuable asset.
          </p>
        </div>
        <br />
        <div style={{ margin: '2vw', marginLeft: '10vw', marginRight: '10vw' }}>
          <h1
            className='display-4 text-center fw-bold'
            style={{ marginTop: '5vw', marginBottom: '5vw' }}
          >
            Expanded Choices. 
            Greater Flexibility.
          </h1>
          <p className='fs-5 p-1'>
            Amplify creates an open marketplace where subscribers are able to 
            choose either a small set of individual applications or a full-featured 
            traditional MLS system, or both.
          </p>
          <p className='fs-5 p-1'>
            Open architecture flexibility enables us to bridge diverse environments 
            and use each one to its fullest. It enables MLSs to respond to shifts in 
            demand, industry changes, or your own IT priorities.
          </p>
          <p className='fs-5 p-1'>
            Streamline MLS consolidation without service disruption.
          </p>
          <p className='fs-5 p-1'>
            Expand your services to all Real Estate stakeholders in a controlled 
            and secure environment.
          </p>
          <p className='fs-5 p-1'>
            With Amplify’s open architecture, application developers can innovate 
            like never before, benefiting from solutions that are both innovative 
            and enterprise-ready.
          </p>
          <p className='fs-5 p-1'>
            Amplify allows MLSs to maintain control over their data and 
            application offerings.
          </p>
        </div>
      </Section>
      <SectionGray className='border' id='products'>
        <div style={{ marginLeft: '10vw', marginRight: '10vw' }}>
          <h1
            className='display-4 text-center fw-bold text-white'
            style={{ marginBottom: '5vw' }}
          >
            It's About Choice
          </h1>
          <p className='fs-5 text-white'>
            No matter which model you choose, the Amplify platform provides 
            ways for subscribers to use different tools for different tasks:
          </p>
          <div className='p-4'>
            <p className='fs-5 text-white'>
              <b>Listing agents</b> who may only need CMA and market research tools.
            </p>
            <p className='fs-5 text-white'>
              <b>Buyer agents</b> who may only need property search and tour info.
            </p>
            <p className='fs-5 text-white'>
              <b>Appraisers</b> who only need comparative properties and in-depth 
              valuation tools.
            </p>
          </div>
          <p className='fs-5 text-white'>
            It’s important to note that MLSs control the relationship with partnering 
            developers and software companies. Developers must follow industry 
            standards for requesting and receiving data.
          </p>
        </div>
        <div className='p-2' id='framework'>
          <h1
            className='display-4 text-center fw-bold text-white'
            style={{ marginTop: '5vw', marginBottom: '5vw' }}
          >
            Our Framework
          </h1>
          <div className='container mx-auto'>
            <div className='row'>
              <div className='col-sm'>
                <div className='text-center mx-auto p-4'>
                  <img
                    src={Database}
                    className='img-fluid'
                    style={styles.image(isHandHeld)}
                    alt='database'
                  />
                  <p className='h2 text-white p-3'>Cloud Database</p>
                  <p className='fs-5 text-white'>
                    RESO compliant, multi-lingual, scalable, fully managed, and
                    high availability.
                  </p>
                </div>
                <div className='text-center mx-auto p-4'>
                  <img
                    src={Framework}
                    className='img-fluid'
                    style={styles.image(isHandHeld)}
                    alt='framework'
                  />
                  <p className='h2 text-white p-3'>API Framework</p>
                  <p className='fs-5 text-white'>
                    Vendors can integrate faster and easier through an interface
                    consistent across all MLSs.
                  </p>
                </div>
              </div>
              <div className='col-sm'>
                <div className='text-center mx-auto p-4'>
                  <img
                    src={Security}
                    className='img-fluid'
                    style={styles.image(isHandHeld)}
                    alt='security'
                  />
                  <p className='h2 text-white p-3'>Security</p>
                  <p className='fs-5 text-white'>
                    Built on the roles-based platform which allows MLSs to
                    define who gets access to what data.
                  </p>
                </div>
                <div className='text-center mx-auto p-4'>
                  <img
                    src={Application}
                    className='img-fluid'
                    style={styles.imageAdjustment(isHandHeld)}
                    alt='application'
                  />
                  <p className='h2 text-white p-3'>Application Market</p>
                  <p className='fs-5 text-white'>
                    Allows agents and brokers to choose only the tools they
                    need.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SectionGray>
      <Section>
        <span className='p-4' id='vendors'>
          <div className='container mx-auto'>
            <h1
              className='display-4 text-center fw-bold'
              style={{ marginBottom: '5vw' }}
            >
              Technology Vendors
            </h1>
            <div className='row'>
              <div className='col-sm'>
                <div className='text-center mx-auto p-4'>
                  <img
                    src={Market}
                    className='img-fluid'
                    style={styles.image(isHandHeld)}
                    alt='vendors'
                  />
                  <p className='h2 p-3'>Faster Time to Market</p>
                  <p className='fs-5'>
                    Developers write programs once and work on any Amplify powered 
                    MLS system anywhere, without modification.
                  </p>
                </div>
                <div className='text-center mx-auto p-4'>
                  <img
                    src={BuiltIn}
                    className='img-fluid'
                    style={styles.image(isHandHeld)}
                    alt='built-in'
                  />
                  <p className='h2 p-3'>Built-In Potential Client Base</p>
                  <p className='fs-5'>
                    Technology vendors access additional open markets, more Real
                    Estate professionals are available to buy their products,
                    and more opportunities arise to increase revenue with lower
                    development and support costs.
                  </p>
                </div>
              </div>
              <div className='col-sm'>
                <div className='text-center mx-auto p-4'>
                  <img
                    src={Integration}
                    className='img-fluid'
                    style={styles.imageAdjustmentTwo(isHandHeld)}
                    alt='integration'
                  />
                  <p className='h2 p-3'>Easier MLS Integrations</p>
                  <p className='fs-5'>
                    Vendors no longer write new code for every MLS system or
                    convert products when the MLS changes system vendors or adds
                    a new data field.
                  </p>
                </div>
                <div className='text-center mx-auto p-4'>
                  <img
                    src={Opportunity}
                    className='img-fluid'
                    style={styles.imageAdjustment(isHandHeld)}
                    alt='opportunity'
                  />
                  <p className='h2 p-3'>
                    Opportunities for New and Innovative Software
                  </p>
                  <p className='fs-5'>
                    Open architecture enables new, smaller developers to enter
                    the marketplace. Developers enable their applications to
                    work in every market, across multiple MLS systems.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </span>
        <span className='p-4' id='brokers'>
          <div className='container'>
            <div className='row'>
              <div className='col-sm text-center'>
                <h1 className='display-4 fw-bold' style={{ margin: '3vw' }}>
                  Brokers
                </h1>
                <div className='mx-auto p-3'>
                  <p className='h2 p-3'>Control Over Applications</p>
                  <p className='fs-5'>
                    Utilize your brand - differentiating technology to promote
                    tools to your agents, allowing them to reduce costs through
                    the unbundling of MLS “core services.”
                  </p>
                </div>
                <div className='mx-auto p-3'>
                  <p className='h2 p-3'>Consistent Broker Branding</p>
                  <p className='fs-5'>
                    Standardize company tools by offering agents approved
                    applications for use in any market where your brokerage
                    operates.
                  </p>
                </div>
                <div className='mx-auto p-3'>
                  <p className='h2 p-3'>Single, Standardized Data Feeds </p>
                  <p className='fs-5'>
                    Receive a single data feed from multiple, participating MLSs
                    in a standardized format, delivered via industry-standard
                    protocols.
                  </p>
                </div>
              </div>
              <div className='col-sm text-center'>
                <h1 className='display-4 fw-bold' style={{ margin: '3vw' }}>
                  Agents
                </h1>
                <div className='mx-auto p-3'>
                  <p className='h2 p-3'>Choice</p>
                  <p className='fs-5'>
                    Choose from a gallery of applications, not previously
                    available through traditional MLS systems.
                  </p>
                </div>
                <div className='mx-auto p-3'>
                  <p className='h2 text-center p-3'>Flexibility</p>
                  <p className='fs-5'>
                    Mix and match tools from different vendors to suit needs,
                    business plans, and work styles.
                  </p>
                </div>
                <div className='mx-auto p-3'>
                  <p className='h2 p-3'>Consistentcy</p>
                  <p className='fs-5'>
                    Data stays in sync as agents work across multiple
                    applications.
                  </p>
                </div>
                <div className='mx-auto p-3'>
                  <p className='h2 p-3'>Increased Efficiencies</p>
                  <p className='fs-5'>
                    Entering and updating listings is easier and more
                    streamlined.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </span>
      </Section>
      <SectionGray className='border'>
        <div style={{ marginLeft: '10vw', marginRight: '10vw' }} id='about'>
          <h1
            className='display-4 text-center fw-bold text-white'
            style={{ marginBottom: '5vw' }}
          >
            About Us
          </h1>
          <p className='fs-5 text-white mx-auto'>
            Amplify Systems LLC is a Scottsdale, AZ based technology company that
            provides enterprise solutions for Real Estate across North America.
            Our mission is to empower Multiple Listing Services and Real Estate
            Brokers to control their data services and application offerings
            without a middle man. Amplify offers a leading technology framework that
            is the industry standard for open-architecture platforms - providing
            unparalleled choice and flexibility.
          </p>
        </div>
        <br />
        <div style={{ marginLeft: '10vw', marginRight: '10vw' }} id='press'>
          <h1
            className='display-4 text-center fw-bold text-white'
            style={{ marginTop: '5vw', marginBottom: '5vw' }}
          >
            Press
          </h1>
          <div className='card bg-light m-0' style={{ alignContent: 'center' }}>
            <div className='card-body'>
              <ReactTinyLink
                cardSize='small'
                showGraphic={false}
                maxLine={2}
                minLine={1}
                width={100}
                header='TRREB JOINS WITH TERANET INC. AND AMPLIFY SYSTEMS LLC TO LAUNCH
                REVOLUTIONARY NEW MULTI-LIST PLATFORM'
                description='Toronto, ON, February 16, 2021 – The Toronto Regional Real Estate Board (TRREB), real estate data
                experts Teranet Inc. and technology specialists Amplify Systems LLC (a sister company of Stratus Data
                Systems) are joining forces to launch an exciting, next-generation Multi-List Platform and Blockchain
                solution.'
                url='https://www.globenewswire.com/news-release/2021/02/16/2176309/0/en/TRREB-Joins-With-Teranet-Inc-and-AMP-Systems-LLC-to-Launch-Revolutionary-New-Multi-List-Platform.html'
              />
              <ReactTinyLink
                cardSize='small'
                showGraphic={false}
                maxLine={2}
                minLine={1}
                width={100}
                header='TRREB to launch “ground-breaking” multi-list technology platform'
                description='The Toronto Regional Real Estate Board has announced plans to launch a new “multi-list” technology platform for more than 60,000 TRREB and partner-board members. “TRREB will be the first board in the country to offer this ground-breaking platform that will offer a more intelligent way for real estate professionals to help and support their clients, manage their business and elevate their integral role in the home ownership journey,” says TRREB CEO John DiMichele.'
                url='https://www.realestatemagazine.ca/trreb-to-launch-ground-breaking-multi-list-technology-platform/'
              />
            </div>
          </div>
        </div>
      </SectionGray>
    </React.Fragment>
  );
};

const styles = {
  image: isHandHeld => ({
    width: isHandHeld ? '10vh' : '8vw'
  }),
  imageAdjustment: isHandHeld => ({
    width: isHandHeld ? '8vh' : '6.4vw'
  }),
  imageAdjustmentTwo: isHandHeld => ({
    width: isHandHeld ? '6.5vh' : '5.6vw'
  })
};

export default Main;
