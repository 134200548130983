import React, { useState } from 'react';

import { Section } from '../static/styles/style';
import { Logo } from '../static/index';

import { useMediaQuery } from '../helpers/hook';

const Contact = () => {
  const [hover, isHovering] = useState(false);

  const toggleHover = e => {
    e.preventDefault();
    isHovering(!hover);
  };

  const isHandHeld = useMediaQuery('(max-width: 650px)');

  return (
    <React.Fragment>
      <Section>
        <div className='container'>
          <div className='row'>
            <div className='col-sm p-4' id='contact'>
              <a href='#intro'>
                <img
                  src={Logo}
                  className='img-thumbnail border-0 p-3'
                  alt='Amplify Systems Logo'
                  onMouseEnter={toggleHover}
                  onMouseLeave={toggleHover}
                  style={styles.logo(isHandHeld, hover)}
                />
              </a>
              <div className='p-3'>
                <p className='h2 m-0 text-start'>Contact Us</p>
                <br />
                <div className='p-0'>
                  <p className='h4'>Email Address</p>
                  <a className='fs-5' href='mailto:info@amptech.us'>
                    info@amptech.us
                  </a>
                  <br />
                  <br />
                  <p className='h4'>Company Address</p>
                  <p className='fs-5'>
                    Amplify Systems LLC
                    <br />
                    PO BOX 15457
                    <br />
                    Scottsdale, AZ 85267
                  </p>
                </div>
              </div>
            </div>
            <div className='col-sm text-start'>
              <div style={{ margin: '4vw' }}>
                <p className='fs-4'>
                  <a className='text-decoration-none' href='#intro'>
                    What is Amplify?
                  </a>
                </p>
                <p className='fs-4'>
                  <a className='text-decoration-none' href='#products'>
                    Products
                  </a>
                </p>
                <p className='fs-4'>
                  <a className='text-decoration-none' href='#framework'>
                    Our Framework
                  </a>
                </p>
                <p className='fs-4'>
                  <a className='text-decoration-none' href='#vendors'>
                    Technology Vendors
                  </a>
                </p>
                <p className='fs-4'>
                  <a className='text-decoration-none' href='#brokers'>
                    Brokers and Agents
                  </a>
                </p>
                <p className='fs-4'>
                  <a className='text-decoration-none' href='#about'>
                    About Us
                  </a>
                </p>
                <p className='fs-4'>
                  <a className='text-decoration-none' href='#press'>
                    Press
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Section>
    </React.Fragment>
  );
};

const styles = {
  logo: (isHandHeld, hover) => ({
    width: isHandHeld ? '11.25vh' : '15vw',
    opacity: hover ? '0.85' : '',
    cursor: hover ? 'pointer' : ''
  })
};

export default Contact;
