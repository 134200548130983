import Navbar from '../components/navbar.component';
import Footer from '../components/footer.component';
import Main from '../pages/main';
import Contact from '../components/contact.component';

function MainView() {
  return (
    <div className='App'>
      <Navbar />
      <Main />
      <Contact />
      <Footer />
    </div>
  );
}

export default MainView;
