import styled from 'styled-components';
import { HoneyComb } from '../index';

export const Image = styled.img`
  padding: '2vw';
  border-radius: '5vw';

  @media (max-width: 650px) {
    padding: '2vh';
    border-radius: '5vh';
  }
`;

export const ImageWrapper = styled.div`
  width: '40vw';

  @media (max-width: 650px) {
    width: '30vh';
  }
`;

export const Section = styled.section`
  padding: 10vw;
  text-align: left;
`;

export const SectionGray = styled.section`
  padding: 10vw;
  background-position: left;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.7;
  text-align: left;
  box-shadow: inset 0 0 0 2000px rgba(108, 126, 137, 0.7);
  background-image: url(${HoneyComb});
`;
