import React from 'react';

const Footer = () => {
  let d = new Date();
  return (
    <React.Fragment>
      <footer className='footer mt-auto py-3 bg-light'>
        <div className='container text-center'>
          <span className='text-muted'>
            Copyright &#169; {d.getFullYear()} Amplify Systems LLC. All rights
            reserved.
          </span>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
