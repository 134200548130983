import React, { useState } from 'react';

import { Helmet } from 'react-helmet';
import { Logo } from '../static/index';

import { useMediaQuery } from '../helpers/hook';

const Cover = () => {
  const [hover, isHovering] = useState(false);

  const url = window.location.protocol + window.location.host;

  const toggleHover = e => {
    e.preventDefault();
    isHovering(!hover);
  };

  const isHandHeld = useMediaQuery('(max-width: 650px)');

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Amplify Systems LLC - Offical Site</title>
        <meta
          name='description'
          content={
            'Amplify Systems LLC is a Scottsdale, AZ based technology company that provides enterprise solutions for Real Estate across North America.'
          }
        />
        <link rel='canonical' href={url} />
      </Helmet>
      <div className='container-sm' style={styles.container(isHandHeld)}>
        <a href='#intro'>
          <img
            src={Logo}
            className='img-fluid'
            onMouseEnter={toggleHover}
            onMouseLeave={toggleHover}
            style={styles.logo(isHandHeld, hover)}
            alt='Amplify Systems Logo'
          />
        </a>
      </div>
    </React.Fragment>
  );
};

const styles = {
  container: isHandHeld => ({
    width: isHandHeld ? '35vh' : '46.66vw'
  }),
  logo: (isHandHeld, hover) => ({
    padding: isHandHeld ? '1vh' : '1vw',
    opacity: hover ? '0.85' : '',
    cursor: hover ? 'pointer' : ''
  })
};

export default Cover;
