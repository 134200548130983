import React from 'react';
import { LogoBlack } from '../static';

const Navbar = () => {
  return (
    <React.Fragment>
      <nav className='navbar  navbar-expand-lg navbar-light'>
        <div className='container-fluid m-4'>
          <a className='navbar-brand' href='#intro'>
            <b>
              <img src={LogoBlack} alt="Amplify" height="34" />
            </b>
          </a>
          <button
            className='navbar-toggler'
            type='button'
            data-bs-toggle='collapse'
            data-bs-target='#navbarText'
            aria-controls='navbarText'
            aria-expanded='false'
            aria-label='Toggle navigation'
          >
            <span className='navbar-toggler-icon'></span>
          </button>
          <div className='collapse navbar-collapse' id='navbarText'>
            <ul className='navbar-nav me-auto mb-2 mb-lg-0'>
              <li className='nav-item'>
                <a className='nav-link' aria-current='page' href='#products'>
                  Products
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link' href='#vendors'>
                  How It Works
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link' href='#about'>
                  About Us
                </a>
              </li>
              <li className='nav-item'>
                <a className='nav-link' href='#press'>
                  Press
                </a>
              </li>
            </ul>
            <span className='navbar-text'>
              <a href='#contact' className='text-decoration-none'>
                Contact Us
              </a>
            </span>
          </div>
        </div>
      </nav>
      <hr style={{ margin: '0' }} />
    </React.Fragment>
  );
};

export default Navbar;
